<template>
  <v-app>
    <Snackbar />

    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <!-- <dashboard-core-view > -->
      <v-main>
        <v-container fluid >
          <v-row>
            <v-col>
              <base-material-card icon="mdi-account-group">
                <template v-slot:heading>
                  <h3 class="display-2">Ganti Password</h3>
                </template>
                <v-row>
                  <v-col cols="9">
                    <v-form ref="refForm">
                      <p class="ma-3">Password Lama Anda:</p>
                      <v-text-field dense v-model="formData.old_password"
                      :type="oldPassword ? 'text' : 'password'"
                      @click:append="()=>(oldPassword =! oldPassword)"
                      :append-icon="oldPassword ? 'mdi-eye' : 'mdi-eye-off' " :rules="[rules.required]" outlined label="Masukan password lama anda"></v-text-field>
                      <p class="ma-3">Password Baru Anda:</p>
                      <v-text-field dense v-model="formData.new_password"
                      :type="newPassword ? 'text' : 'password'"
                      @click:append="()=>(newPassword =! newPassword)" :append-icon="newPassword ? 'mdi-eye' : 'mdi-eye-off' " :rules="[rules.required]" outlined label="Masukan password baru anda"></v-text-field>
                      <p class="ma-3">Konfirmasi Password Baru Anda:</p>
                      <v-text-field dense v-model="formData.confirm_new_password"
                      :type="confirmNewPassword ? 'text' : 'password'"
                      @click:append="()=>(confirmNewPassword =! confirmNewPassword)" :append-icon="confirmNewPassword ? 'mdi-eye' : 'mdi-eye-off' " :rules="[rules.required]" outlined label="Masukan kembali password baru anda"></v-text-field>
                    </v-form>
                  </v-col>
                  <v-col cols="3">
                  </v-col>
                </v-row>
                <v-btn color="success" @click="simpanPassword()">
                  <v-icon small left>mdi-content-save</v-icon>
                  Simpan Password
                </v-btn>
              </base-material-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    <!-- </dashboard-core-view> -->

    <dashboard-core-settings />

    <dashboard-core-footer />
  </v-app>
</template>

<script>
  import Snackbar from '@/components/common/Snackbar'
  import store from '@/store'
  import {obj2Arr, defaultRules} from '@/utils/lib'
  import UserService from '@/services/UserService'
  export default {
    name:"Default",
    components: {
      Snackbar,
      DashboardCoreAppBar: () => import('@/components/core/AppBar'),
      DashboardCoreDrawer: () => import('@/components/core/Drawer'),
      DashboardCoreSettings: () => import('@/components/core/Settings'),
      // DashboardCoreView: () => import('@/components/core/View'),
      DashboardCoreFooter: () => import('@/components/core/Footer')
    },
    data(){
      return {
        rules:{
          ...defaultRules
        },
        formData:{},
        oldPassword: false,
        newPassword: false,
        confirmNewPassword: false,
      }
    },
    created(){
      // set theme from default
      this.$vuetify.theme.dark = store.state.settings.darkMode
      let primary = store.state.settings.primaryColor
      if (primary){
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = primary
      }
    },
    methods:{
      simpanPassword(){
        if (this.$refs.refForm.validate()){
          UserService.changePassword(this.formData).then(response => {
            let res = response.data
            if (res.status == 200){
              store.commit('snackbar/setSnack',{message: res.message + ' Silahkan login kembali menggunakan password baru anda. Terima kasih :)', color:'success'})
              setTimeout( () => {
                console.log('call logout')
                store.dispatch("user/logout")
              }, 1000);
            } else {
              store.commit('snackbar/setSnack',{message: res.message, color:'error'})
            }
          })
        }
      }
    }
  }
</script>


<style lang="css" scoped>
</style>
